import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonComponentsModule } from '../commoncomponents.module';
import { ContactoRoutingModule } from './contacto-routing.module';
import { ContactoSIUuTCREAR4INSTALACIONComponent } from './siu_tcrear4instalacion.component';
import { ContactoSIUuTCREAR4SISTEMAComponent } from './siu_tcrear4sistema.component';
import { ContactoSIUuTEDITComponent } from './siu_tedit.component';
import { ContactoPIUuSistemaComponent } from './piu_sistema.component';

@NgModule({
  imports: [
    CommonModule,
    CommonComponentsModule,
    ContactoRoutingModule
  ],
  declarations: [
    ContactoSIUuTCREAR4INSTALACIONComponent,
    ContactoSIUuTCREAR4SISTEMAComponent,
    ContactoSIUuTEDITComponent,
    ContactoPIUuSistemaComponent
  ],
  exports: [
    ContactoSIUuTCREAR4INSTALACIONComponent,
    ContactoSIUuTCREAR4SISTEMAComponent,
    ContactoSIUuTEDITComponent,
    ContactoPIUuSistemaComponent
  ]
})
export class ContactoModule { }
