import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { AppGlobalInfo } from '../app.appglobalinfo';
import { CacheDependencyRules, InstanceCache } from '../common/app.cachedependencyrules';
import { Field, FieldDefinedSelection, FieldOVLense, FieldOVLenseSearch, Instance } from '../common/baseIUElements';
import { AbstractServiceIU } from '../common/abstractSIU';
import { ExchangeInfo } from '../common/app.exchangeinfo';
import { ModelConstants } from '../common/model.constants';
import { Util } from '../common/app.utils';
import { DefinedSelections } from '../common/definedSelection';
import { StandardFunctions } from '../common/standardFunctions';
import { LanguageMng } from '../common/languageMng';
import { ConditionalNavigationMng } from '../common/conditionalNavigationMng';
import { UserFunctions } from '../common/userFunctions';
@Component({
    selector: 'imes-contacto-siu-tedit',
    templateUrl: './siu_tedit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactoSIUuTEDITComponent extends AbstractServiceIU implements OnInit {
    /** Fields */
    public puthisContacto: FieldOVLenseSearch;
    public ptupuNombre: Field;
    public ptupuTelefono1: Field;
    public ptupuTelefono2: Field;
    public ptupuFax: Field;
    public ptupuEmail: Field;
    public ptupuRecibePresupuestos: FieldDefinedSelection;
    public ptupuRecibePartes: FieldDefinedSelection;
    public ptupuRecibeFacturas: FieldDefinedSelection;
    public ptupuPuedeFirmar: FieldDefinedSelection;
    public ptupuDNI: Field;
    public ptupuMovil: Field;
    public ptupuTipoFirma: FieldDefinedSelection;
    public ptupuIdDispositivo: Field;
    public ptupuTambienDeCRA: FieldDefinedSelection;
    public pArea: FieldOVLense;
    public pClave: Field;
    public pClaveCoaccion: Field;

    /**
     * Component constructor
     * @param appGlobalInfo Application Global Information
     * @param util Navigation utils
     * @param changeDetectorRef Angular module
     * @param langMng Language manager
     * @param stdFun Standard functions
     * @param condNavMng Conditional manager
     * @param usrFunc User functions
     */
    constructor(
        public appGlobalInfo: AppGlobalInfo, public util: Util, public changeDetectorRef: ChangeDetectorRef,
        public langMng: LanguageMng, public stdFun: StandardFunctions,
        public condNavMng: ConditionalNavigationMng, public usrFunc: UserFunctions) {
        super(appGlobalInfo, util, changeDetectorRef, condNavMng);
        this.idXML = 'Clas_1699352150016154Ser_10UIServ_1';
        this.className = ModelConstants.Contacto.name;
        this.iuName = ModelConstants.Contacto.siuutedit;
        this.setUserVisibility(this.appGlobalInfo.getLoggedUserInfo().isIUVisible(this.idXML, ['Cliente']));
        this.serviceName = 'TEDIT';
        this.title = this.langMng.translateText('cls_Contacto_svc_TEDIT',
            'Modificar contacto');
    }

    public ngOnInit(): void {
        this.myngOnInit();
    }

    /**
     * Initialize the component using default values
     */
    public initializeComponents(): void {

        this.puthisContacto = new FieldOVLenseSearch(this.langMng);
        this.puthisContacto.nameInRequest = 'p_thiscontacto';
        this.puthisContacto.alias = this.langMng.translateText(
            'cls_Contacto_svc_TEDIT_inArg_p_thisContacto',
            'Contacto');
        this.puthisContacto.visible = this.util.isNull(this.queryContainer) ||
            this.queryContainer.className !== this.className;
        this.puthisContacto.mandatory = true;
        this.puthisContacto.dataType = ModelConstants.Contacto.name;
        this.puthisContacto.maxLength = 300;
        this.puthisContacto.assignCSS();
        this.fields.push(this.puthisContacto);

        this.ptupuNombre = new Field(this.langMng);
        this.ptupuNombre.nameInRequest = 'pt_p_nombre';
        this.ptupuNombre.alias = this.langMng.translateText(
            'cls_Contacto_svc_TEDIT_inArg_pt_p_Nombre',
            'Nombre');
        this.ptupuNombre.mandatory = false;
        this.ptupuNombre.dataType = Field.dtString;
        this.ptupuNombre.maxLength = 300;
        this.ptupuNombre.assignCSS();
        this.fields.push(this.ptupuNombre);

        this.ptupuTelefono1 = new Field(this.langMng);
        this.ptupuTelefono1.nameInRequest = 'pt_p_telefono1';
        this.ptupuTelefono1.alias = this.langMng.translateText(
            'cls_Contacto_svc_TEDIT_inArg_pt_p_Telefono1',
            'Teléfono 1');
        this.ptupuTelefono1.mandatory = false;
        this.ptupuTelefono1.dataType = Field.dtString;
        this.ptupuTelefono1.maxLength = 20;
        this.ptupuTelefono1.assignCSS();
        this.fields.push(this.ptupuTelefono1);

        this.ptupuTelefono2 = new Field(this.langMng);
        this.ptupuTelefono2.nameInRequest = 'pt_p_telefono2';
        this.ptupuTelefono2.alias = this.langMng.translateText(
            'cls_Contacto_svc_TEDIT_inArg_pt_p_Telefono2',
            'Teléfono 2');
        this.ptupuTelefono2.mandatory = false;
        this.ptupuTelefono2.dataType = Field.dtString;
        this.ptupuTelefono2.maxLength = 20;
        this.ptupuTelefono2.assignCSS();
        this.fields.push(this.ptupuTelefono2);

        this.ptupuFax = new Field(this.langMng);
        this.ptupuFax.nameInRequest = 'pt_p_fax';
        this.ptupuFax.alias = this.langMng.translateText(
            'cls_Contacto_svc_TEDIT_inArg_pt_p_Fax',
            'Fax');
        this.ptupuFax.mandatory = false;
        this.ptupuFax.dataType = Field.dtString;
        this.ptupuFax.maxLength = 20;
        this.ptupuFax.assignCSS();
        this.fields.push(this.ptupuFax);

        this.ptupuEmail = new Field(this.langMng);
        this.ptupuEmail.nameInRequest = 'pt_p_email';
        this.ptupuEmail.alias = this.langMng.translateText(
            'cls_Contacto_svc_TEDIT_inArg_pt_p_Email',
            'Email');
        this.ptupuEmail.mandatory = false;
        this.ptupuEmail.dataType = Field.dtString;
        this.ptupuEmail.maxLength = 100;
        this.ptupuEmail.assignCSS();
        this.fields.push(this.ptupuEmail);

        this.ptupuRecibePresupuestos = new FieldDefinedSelection(this.langMng);
        this.ptupuRecibePresupuestos.nameInRequest = 'pt_p_recibepresupuestos';
        this.ptupuRecibePresupuestos.alias = this.langMng.translateText(
            'cls_Contacto_svc_TEDIT_inArg_pt_p_RecibePresupuestos',
            'Recibe presupuestos');
        this.ptupuRecibePresupuestos.mandatory = true;
        this.ptupuRecibePresupuestos.dataType = Field.dtBool;
        this.ptupuRecibePresupuestos.options = DefinedSelections.DS_SI_NO;
        this.ptupuRecibePresupuestos.value = false;
        this.ptupuRecibePresupuestos.assignCSS();
        this.fields.push(this.ptupuRecibePresupuestos);

        this.ptupuRecibePartes = new FieldDefinedSelection(this.langMng);
        this.ptupuRecibePartes.nameInRequest = 'pt_p_recibepartes';
        this.ptupuRecibePartes.alias = this.langMng.translateText(
            'cls_Contacto_svc_TEDIT_inArg_pt_p_RecibePartes',
            'Recibe partes');
        this.ptupuRecibePartes.mandatory = true;
        this.ptupuRecibePartes.dataType = Field.dtBool;
        this.ptupuRecibePartes.options = DefinedSelections.DS_SI_NO;
        this.ptupuRecibePartes.value = false;
        this.ptupuRecibePartes.assignCSS();
        this.fields.push(this.ptupuRecibePartes);

        this.ptupuRecibeFacturas = new FieldDefinedSelection(this.langMng);
        this.ptupuRecibeFacturas.nameInRequest = 'pt_p_recibefacturas';
        this.ptupuRecibeFacturas.alias = this.langMng.translateText(
            'cls_Contacto_svc_TEDIT_inArg_pt_p_RecibeFacturas',
            'Recibe facturas');
        this.ptupuRecibeFacturas.mandatory = true;
        this.ptupuRecibeFacturas.dataType = Field.dtBool;
        this.ptupuRecibeFacturas.options = DefinedSelections.DS_SI_NO;
        this.ptupuRecibeFacturas.value = false;
        this.ptupuRecibeFacturas.assignCSS();
        this.fields.push(this.ptupuRecibeFacturas);

        this.ptupuPuedeFirmar = new FieldDefinedSelection(this.langMng);
        this.ptupuPuedeFirmar.nameInRequest = 'pt_p_puedefirmar';
        this.ptupuPuedeFirmar.alias = this.langMng.translateText(
            'cls_Contacto_svc_TEDIT_inArg_pt_p_PuedeFirmar',
            'Puede firmar');
        this.ptupuPuedeFirmar.mandatory = false;
        this.ptupuPuedeFirmar.dataType = Field.dtBool;
        this.ptupuPuedeFirmar.options = DefinedSelections.DS_SI_NO;
        this.ptupuPuedeFirmar.assignCSS();
        this.fields.push(this.ptupuPuedeFirmar);

        this.ptupuDNI = new Field(this.langMng);
        this.ptupuDNI.nameInRequest = 'pt_p_dni';
        this.ptupuDNI.alias = this.langMng.translateText(
            'cls_Contacto_svc_TEDIT_inArg_pt_p_DNI',
            'DNI');
        this.ptupuDNI.mandatory = false;
        this.ptupuDNI.dataType = Field.dtString;
        this.ptupuDNI.maxLength = 15;
        this.ptupuDNI.fieldSize = 10;
        this.ptupuDNI.assignCSS();
        this.fields.push(this.ptupuDNI);

        this.ptupuMovil = new Field(this.langMng);
        this.ptupuMovil.nameInRequest = 'pt_p_movil';
        this.ptupuMovil.alias = this.langMng.translateText(
            'cls_Contacto_svc_TEDIT_inArg_pt_p_Movil',
            'Móvil');
        this.ptupuMovil.mandatory = false;
        this.ptupuMovil.dataType = Field.dtString;
        this.ptupuMovil.maxLength = 15;
        this.ptupuMovil.assignCSS();
        this.fields.push(this.ptupuMovil);

        this.ptupuTipoFirma = new FieldDefinedSelection(this.langMng);
        this.ptupuTipoFirma.nameInRequest = 'pt_p_tipofirma';
        this.ptupuTipoFirma.alias = this.langMng.translateText(
            'cls_Contacto_svc_TEDIT_inArg_pt_p_TipoFirma',
            'Tipo firma');
        this.ptupuTipoFirma.mandatory = false;
        this.ptupuTipoFirma.dataType = Field.dtString;
        this.ptupuTipoFirma.options = DefinedSelections.DS_TIPOFIRMA;
        this.ptupuTipoFirma.maxLength = 1;
        this.ptupuTipoFirma.assignCSS();
        this.fields.push(this.ptupuTipoFirma);

        this.ptupuIdDispositivo = new Field(this.langMng);
        this.ptupuIdDispositivo.nameInRequest = 'pt_p_iddispositivo';
        this.ptupuIdDispositivo.alias = this.langMng.translateText(
            'cls_Contacto_svc_TEDIT_inArg_pt_p_IdDispositivo',
            'Id. dispositivo');
        this.ptupuIdDispositivo.mandatory = false;
        this.ptupuIdDispositivo.dataType = Field.dtString;
        this.ptupuIdDispositivo.maxLength = 100;
        this.ptupuIdDispositivo.assignCSS();
        this.fields.push(this.ptupuIdDispositivo);

        this.ptupuTambienDeCRA = new FieldDefinedSelection(this.langMng);
        this.ptupuTambienDeCRA.nameInRequest = 'pt_p_tambiendecra';
        this.ptupuTambienDeCRA.alias = this.langMng.translateText(
            'cls_Contacto_svc_TEDIT_inArg_pt_p_TambienDeCRA',
            'Es de CRA?');
        this.ptupuTambienDeCRA.mandatory = true;
        this.ptupuTambienDeCRA.dataType = Field.dtBool;
        this.ptupuTambienDeCRA.options = DefinedSelections.DS_SI_NO;
        this.ptupuTambienDeCRA.value = false;
        this.ptupuTambienDeCRA.assignCSS();
        this.fields.push(this.ptupuTambienDeCRA);

        this.pArea = new FieldOVLense(this.langMng);
        this.pArea.nameInRequest = 'parea';
        this.pArea.alias = this.langMng.translateText(
            'cls_Contacto_svc_TEDIT_inArg_pArea',
            'Área');
        this.pArea.mandatory = false;
        this.pArea.dataType = ModelConstants.Area.name;
        this.pArea.oidFields = Array.apply(null, Array(1));
        this.pArea.oidFieldsTypes = ['autonumerico'];
        this.pArea.fieldSize = 50;
        this.pArea.assignCSS();
        this.fields.push(this.pArea);

        this.pClave = new Field(this.langMng);
        this.pClave.nameInRequest = 'pclave';
        this.pClave.alias = this.langMng.translateText(
            'cls_Contacto_svc_TEDIT_inArg_pClave',
            'Clave');
        this.pClave.mandatory = false;
        this.pClave.dataType = Field.dtString;
        this.pClave.maxLength = 100;
        this.pClave.assignCSS();
        this.fields.push(this.pClave);

        this.pClaveCoaccion = new Field(this.langMng);
        this.pClaveCoaccion.nameInRequest = 'pclavecoaccion';
        this.pClaveCoaccion.alias = this.langMng.translateText(
            'cls_Contacto_svc_TEDIT_inArg_pClaveCoaccion',
            'Clave coacción');
        this.pClaveCoaccion.mandatory = false;
        this.pClaveCoaccion.dataType = Field.dtString;
        this.pClaveCoaccion.maxLength = 100;
        this.pClaveCoaccion.assignCSS();
        this.fields.push(this.pClaveCoaccion);
    }

    /**
     * Initialize with default values
     */
    public initializeDefaultValues(): Promise<void> {
        return new Promise<void>((resolve) => {
            const promiseArr: Array<Promise<void>> = [];

            // pt_p_PuedeFirmar
            const defValptupuPuedeFirmar = new Promise<void>((resolveDefValue) => {
                this.ptupuPuedeFirmar.setValue(false);
                resolveDefValue();
            });
            promiseArr.push(defValptupuPuedeFirmar);

            Promise.all(promiseArr).then((results: any[]) => {
                this.util.hideWaitDialog();
                this.changeDetectorRef.markForCheck();
                resolve();
            });
        });
    }

    /**
     * Initialize from context the 'This' argument
     */
    public initializeFromCtxArgumentThis(): void {
        if (this.context.exchangeInfo.selectedOidsClassName === 'Contacto' &&
            this.context.exchangeInfo.selectedOids && this.context.exchangeInfo.selectedOids.length > 0) {
            this.puthisContacto.setValue(this.context.exchangeInfo.selectedOids);
            this.executeDependecyRulesputhiscontacto('SetValue', true);
            this.puthisContacto.enabled = false;
            this.executeDependecyRulesputhiscontacto('SetEnabled', true);
        }
    }

    /**
     * Initialize arguments from context that identify a Role
     */
    public initializeFromCtxRelated(): void {
        const lastRole = this.context.exchangeInfo.getLastNavigationRole();
        const lastRoleOid = this.context.exchangeInfo.getLastNavigationRoleOid();

        if (lastRole && lastRoleOid !== {}) {
            if (lastRole === 'Area') {
                this.pArea.setValue(lastRoleOid);
                this.setOidFieldsValuepArea();
                this.executeDependecyRulesparea('SetValue', true);
                this.pArea.enabled = false;
                this.executeDependecyRulesparea('SetEnabled', true);
            }
        }
    }

   /**
    * Process the selection backward navigation
    */
    public processSelectionBackward(exchInfo: ExchangeInfo): void {
        if (!(exchInfo.selectedOids && exchInfo.selectedOids.length > 0)) {
            return;
        }
        if (exchInfo.originArgumentName.toLowerCase() === 'puthisContacto'.toLowerCase()) {
            this.puthisContacto.addOids(exchInfo.selectedOids);
            this.executeDependecyRulesputhiscontacto('SetValue', false);
        }

        if (exchInfo.originArgumentName.toLowerCase() === 'pArea'.toLowerCase()) {
            this.pArea.addOids(exchInfo.selectedOids);
            this.setOidFieldsValuepArea();
            this.executeDependecyRulesparea('SetValue', false);
        }

    }

    /**
     * Evaluate dependendy rules for p_thisContacto argument
     * @param event Rule event
     * @param internal Internal or External
     */
    private executeDependecyRulesputhiscontacto(event: string, internal: boolean): void {
        // Suplementary information
        if ('SetValue' === event) {
            this.setSuppInfoputhiscontacto();
        }
        if ('SetValue' === event && this.puthisContacto.getSelectedInstances().length > 1) {
            return;
        }
        if (!this.cacheDependencyRules.checkCounter()) {
            return;
        }
        // Rule 1
        if ('SetValue' === event) {
            this.executeDependecyRules1puthisContacto();
        }
        // Rule 2
        if ('SetValue' === event) {
            this.executeDependecyRules2puthisContacto();
        }
        this.executePendingDependencyRules();
    }

    /**
     * Set the supplementary information to the p_thisContacto argument
     */
    private setSuppInfoputhiscontacto(): void {

        const displaySetIC = 'Nombre';
        let numberMissingSupInfo = 0;
        for (const value of this.puthisContacto.getSelectedInstances()) {
            if (!value.supplementaryInfo || value.supplementaryInfo === '') {
                numberMissingSupInfo++;
                this.cacheDependencyRules.addQueryInstance(ModelConstants.Contacto.name, value.oid, displaySetIC);
            }
        }

        if (numberMissingSupInfo === 0) {
            this.puthisContacto.setText();
            this.executePendingDependencyRules();
            this.changeDetectorRef.markForCheck();
        } else {
            this.notifyCallToTheBackEnd();
            this.cacheDependencyRules.searchAll().then(() => {
                this.notifyAnswerFromToTheBackEnd();
                for (const value of this.puthisContacto.getSelectedInstances()) {
                    if (!value.supplementaryInfo || value.supplementaryInfo === '') {
                        value.supplementaryInfo = this.cacheDependencyRules.getText(ModelConstants.Contacto.name,
                            value.oid, displaySetIC, [Field.dtString]);
                    }
                }
                this.puthisContacto.setText();
                this.executePendingDependencyRules();
                this.changeDetectorRef.markForCheck();
            });
        }
    }

    /**
     * Dependency rule 1 for p_thisContacto argument
     */
    private executeDependecyRules1puthisContacto(): void {
        this.cacheDependencyRules.counter++;
        this.addQueryInstanceFromField(this.puthisContacto,
                'dni,email,fax,iddispositivo,movil,nombre,puedefirmar,recibefacturas,recibepartes,recibepresupuestos,tambiendecra' +
                ',telefono1,telefono2,tipofirma');
        this.notifyCallToTheBackEnd();
        this.cacheDependencyRules.searchAll().then(() => {
            try {
                this.executeDependecyRules1puthisContactoActions();
            } catch (e) {
                console.log(e);
                this.executePendingDependencyRules();
                this.changeDetectorRef.markForCheck();
            } });
        this.cacheDependencyRules.counter--;
    }

    /**
     * Actions of dependency rule 1 for p_thisContacto argument
     */
    private executeDependecyRules1puthisContactoActions(): void {
        const varputhiscontacto = this.getFirstInstanceFromCache(this.puthisContacto);
        this.notifyAnswerFromToTheBackEnd();
        // v <> NULL
        if (!this.util.isNull(varputhiscontacto)) {
            // pt_p_DNI.SetValue( v.dni )
            this.ptupuDNI.setValue(varputhiscontacto.get('dni'));
            // pt_p_Email.SetValue( v.email )
            this.ptupuEmail.setValue(varputhiscontacto.get('email'));
            // pt_p_Fax.SetValue( v.fax )
            this.ptupuFax.setValue(varputhiscontacto.get('fax'));
            // pt_p_IdDispositivo.SetValue( v.iddispositivo )
            this.ptupuIdDispositivo.setValue(varputhiscontacto.get('iddispositivo'));
            // pt_p_Movil.SetValue( v.movil )
            this.ptupuMovil.setValue(varputhiscontacto.get('movil'));
            // pt_p_Nombre.SetValue( v.nombre )
            this.ptupuNombre.setValue(varputhiscontacto.get('nombre'));
            // pt_p_PuedeFirmar.SetValue( v.puedefirmar )
            this.ptupuPuedeFirmar.setValue(this.util.getBool(varputhiscontacto.get('puedefirmar')));
            this.executeDependecyRulesptupupuedefirmar('SetValue', true);
            // pt_p_RecibeFacturas.SetValue( v.recibefacturas )
            this.ptupuRecibeFacturas.setValue(this.util.getBool(varputhiscontacto.get('recibefacturas')));
            // pt_p_RecibePartes.SetValue( v.recibepartes )
            this.ptupuRecibePartes.setValue(this.util.getBool(varputhiscontacto.get('recibepartes')));
            // pt_p_RecibePresupuestos.SetValue( v.recibepresupuestos )
            this.ptupuRecibePresupuestos.setValue(this.util.getBool(varputhiscontacto.get('recibepresupuestos')));
            // pt_p_TambienDeCRA.SetValue( v.tambiendecra )
            this.ptupuTambienDeCRA.setValue(this.util.getBool(varputhiscontacto.get('tambiendecra')));
            this.executeDependecyRulesptuputambiendecra('SetValue', true);
            // pt_p_Telefono1.SetValue( v.telefono1 )
            this.ptupuTelefono1.setValue(varputhiscontacto.get('telefono1'));
            // pt_p_Telefono2.SetValue( v.telefono2 )
            this.ptupuTelefono2.setValue(varputhiscontacto.get('telefono2'));
            // pt_p_TipoFirma.SetValue( v.tipofirma )
            this.ptupuTipoFirma.setValue(varputhiscontacto.get('tipofirma'));
            this.executeDependecyRulesptuputipofirma('SetValue', true);
            // pt_p_TambienDeCRA.SetValue( v.TambienDeCRA )
            this.ptupuTambienDeCRA.setValue(this.util.getBool(varputhiscontacto.get('tambiendecra')));
            this.executeDependecyRulesptuputambiendecra('SetValue', true);
        }
        this.executePendingDependencyRules();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Dependency rule 2 for p_thisContacto argument
     */
    private executeDependecyRules2puthisContacto(): void {
        this.cacheDependencyRules.counter++;
        this.addQueryInstanceFromField(this.puthisContacto,
                'contactocra.id_ContactoCRA,contactocra.area.id_Area,contactocra.clave,contactocra.clavecoaccion');
        this.notifyCallToTheBackEnd();
        this.cacheDependencyRules.searchAll().then(() => {
            try {
                this.executeDependecyRules2puthisContactoActions();
            } catch (e) {
                console.log(e);
                this.executePendingDependencyRules();
                this.changeDetectorRef.markForCheck();
            } });
        this.cacheDependencyRules.counter--;
    }

    /**
     * Actions of dependency rule 2 for p_thisContacto argument
     */
    private executeDependecyRules2puthisContactoActions(): void {
        const varputhiscontacto = this.getFirstInstanceFromCache(this.puthisContacto);
        this.notifyAnswerFromToTheBackEnd();
        // v <> NULL AND v.contactocra <> NULL
        if (!this.util.isNull(varputhiscontacto) && !this.util.isNull(this.util.oidBuilder.buildOidContactoCRA(
            this.util.getInt(varputhiscontacto.get('contactocra.id_ContactoCRA'))))) {
            // pArea.SetValue( v.contactocra.area )
            this.pArea.setValue(this.util.oidBuilder.buildOidArea(
                this.util.getInt(varputhiscontacto.get('contactocra.area.id_Area'))));
            this.executeDependecyRulesparea('SetValue', true);
            // pClave.SetValue( v.contactocra.clave )
            this.pClave.setValue(varputhiscontacto.get('contactocra.clave'));
            // pClaveCoaccion.SetValue( v.contactocra.clavecoaccion )
            this.pClaveCoaccion.setValue(varputhiscontacto.get('contactocra.clavecoaccion'));
        }
        this.executePendingDependencyRules();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Process the change in the p_thisContacto lense search argument
     */
    public onputhiscontactoChange(): void {

        const searchText = this.puthisContacto.text;
        if (searchText && searchText !== '') {
            if (searchText !== this.puthisContacto.lastText) {
                this.puthisContacto.removeCSSClass('noResultsLenseSearch');
                this.puthisContacto.lastText = searchText;
                this.util.searchLenseSearch(ModelConstants.Contacto.name, 'DSS_Contacto', searchText, [], null).then(
                        (response: any) => {
                            this.puthisContacto.removeCSSClass('noResultsLenseSearch');
                            this.puthisContacto.searchResults = response.results;
                            if (this.puthisContacto.searchResults &&
                                this.puthisContacto.searchResults.length === 0) {
                                this.puthisContacto.addCSSClass('noResultsLenseSearch');
                            }
                            if (this.puthisContacto.searchResults &&
                                this.puthisContacto.searchResults.length === 1) {
                                this.processputhiscontactoSelected(0);
                            }
                            this.changeDetectorRef.markForCheck();
                        },
                        (response: any) => {
                            this.puthisContacto.removeCSSClass('noResultsLenseSearch');
                            this.puthisContacto.searchResults = [];
                            this.util.addErrorMessage(response.message);
                            this.changeDetectorRef.markForCheck();
                        });
            }
        } else {
            this.puthisContacto.removeCSSClass('noResultsLenseSearch');
            this.puthisContacto.searchResults = [];
            this.cleanputhiscontacto();
        }

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Process the selection in the p_thisContacto lense search argument
     */
    public processputhiscontactoSelected(index: number): void {

        let selectedInstance = null;
        if (index !== -1 && this.puthisContacto.searchResults.length > index) {
            selectedInstance = this.puthisContacto.searchResults[index];
        }
        // Clean the result list
        this.puthisContacto.searchResults = [];
        if (selectedInstance) {
            if (!this.puthisContacto.allowMultiSelect) {
                this.puthisContacto.cleanSelectedInstances();
            }
            selectedInstance.oid = JSON.parse(selectedInstance.jsonOID);
            this.puthisContacto.getSelectedInstances().push(selectedInstance);
        }
        this.executeDependecyRulesputhiscontacto('SetValue', false);
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Clean the value of the p_thisContacto lense search argument
     */
    public cleanputhiscontacto(): void {
        this.clearValidationMessages();
        this.puthisContacto.clean();
        this.executeDependecyRulesputhiscontacto('SetValue', false);
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Evaluate dependendy rules for pt_p_PuedeFirmar argument
     * @param event Rule event
     * @param internal Internal or External
     */
    private executeDependecyRulesptupupuedefirmar(event: string, internal: boolean): void {
        if (!this.cacheDependencyRules.checkCounter()) {
            return;
        }
        // Rule 1
        if ('SetValue' === event) {
            this.executeDependecyRules1ptupuPuedeFirmar();
        }
        // Rule 2
        if ('SetValue' === event) {
            this.executeDependecyRules2ptupuPuedeFirmar();
        }
        this.executePendingDependencyRules();
    }

    /**
     * Dependency rule 1 for pt_p_PuedeFirmar argument
     */
    private executeDependecyRules1ptupuPuedeFirmar(): void {
        this.cacheDependencyRules.counter++;
        try {
            this.executeDependecyRules1ptupuPuedeFirmarActions();
        } catch (e) {
            console.log(e);
            this.executePendingDependencyRules();
            this.changeDetectorRef.markForCheck();
        }
        this.cacheDependencyRules.counter--;
    }

    /**
     * Actions of dependency rule 1 for pt_p_PuedeFirmar argument
     */
    private executeDependecyRules1ptupuPuedeFirmarActions(): void {
        // v <> NULL AND v=TRUE
        if (!this.util.isNull(this.util.getBool(this.ptupuPuedeFirmar.value)) &&
            this.util.valueEquals(this.util.getBool(this.ptupuPuedeFirmar.value), true)) {
            // pt_p_DNI.SetVisible( TRUE )
            this.ptupuDNI.visible = true;
            // pt_p_Email.SetVisible( TRUE )
            this.ptupuEmail.visible = true;
            // pt_p_Movil.SetVisible( TRUE )
            this.ptupuMovil.visible = true;
            // pt_p_TipoFirma.SetVisible( TRUE )
            this.ptupuTipoFirma.visible = true;
            // pt_p_DNI.SetMandatory( TRUE )
            this.ptupuDNI.mandatory = true;
            // pt_p_Email.SetMandatory( TRUE )
            this.ptupuEmail.mandatory = true;
            // pt_p_Movil.SetMandatory( TRUE )
            this.ptupuMovil.mandatory = true;
            // pt_p_TipoFirma.SetMandatory( TRUE )
            this.ptupuTipoFirma.mandatory = true;
        }
        this.executePendingDependencyRules();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Dependency rule 2 for pt_p_PuedeFirmar argument
     */
    private executeDependecyRules2ptupuPuedeFirmar(): void {
        this.cacheDependencyRules.counter++;
        try {
            this.executeDependecyRules2ptupuPuedeFirmarActions();
        } catch (e) {
            console.log(e);
            this.executePendingDependencyRules();
            this.changeDetectorRef.markForCheck();
        }
        this.cacheDependencyRules.counter--;
    }

    /**
     * Actions of dependency rule 2 for pt_p_PuedeFirmar argument
     */
    private executeDependecyRules2ptupuPuedeFirmarActions(): void {
        // v <> NULL AND v=FALSE
        if (!this.util.isNull(this.util.getBool(this.ptupuPuedeFirmar.value)) &&
            this.util.valueEquals(this.util.getBool(this.ptupuPuedeFirmar.value), false)) {
            // pt_p_DNI.SetVisible( FALSE )
            this.ptupuDNI.visible = false;
            // pt_p_Email.SetVisible( FALSE )
            this.ptupuEmail.visible = false;
            // pt_p_Movil.SetVisible( FALSE )
            this.ptupuMovil.visible = false;
            // pt_p_TipoFirma.SetVisible( FALSE )
            this.ptupuTipoFirma.visible = false;
            // pt_p_DNI.SetMandatory( FALSE )
            this.ptupuDNI.mandatory = false;
            // pt_p_Email.SetMandatory( FALSE )
            this.ptupuEmail.mandatory = false;
            // pt_p_Movil.SetMandatory( FALSE )
            this.ptupuMovil.mandatory = false;
            // pt_p_TipoFirma.SetMandatory( FALSE )
            this.ptupuTipoFirma.mandatory = false;
        }
        this.executePendingDependencyRules();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Process the change in the pt_p_PuedeFirmar argument
     */
    public onptupupuedefirmarChange(): void {
        this.clearValidationMessages();
        this.executeDependecyRulesptupupuedefirmar('SetValue', false);
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Evaluate dependendy rules for pt_p_TipoFirma argument
     * @param event Rule event
     * @param internal Internal or External
     */
    private executeDependecyRulesptuputipofirma(event: string, internal: boolean): void {
        if (!this.cacheDependencyRules.checkCounter()) {
            return;
        }
        // Rule 1
        if ('SetValue' === event) {
            this.executeDependecyRules1ptupuTipoFirma();
        }
        // Rule 2
        if ('SetValue' === event) {
            this.executeDependecyRules2ptupuTipoFirma();
        }
        // Rule 3
        if ('SetValue' === event) {
            this.executeDependecyRules3ptupuTipoFirma();
        }
        this.executePendingDependencyRules();
    }

    /**
     * Dependency rule 1 for pt_p_TipoFirma argument
     */
    private executeDependecyRules1ptupuTipoFirma(): void {
        this.cacheDependencyRules.counter++;
        try {
            this.executeDependecyRules1ptupuTipoFirmaActions();
        } catch (e) {
            console.log(e);
            this.executePendingDependencyRules();
            this.changeDetectorRef.markForCheck();
        }
        this.cacheDependencyRules.counter--;
    }

    /**
     * Actions of dependency rule 1 for pt_p_TipoFirma argument
     */
    private executeDependecyRules1ptupuTipoFirmaActions(): void {
        // v <> NULL AND v = "B"
        if (!this.util.isNull(this.ptupuTipoFirma.value) && this.util.valueEquals(this.ptupuTipoFirma.value, 'B')) {
            // pt_p_IdDispositivo.SetVisible( TRUE )
            this.ptupuIdDispositivo.visible = true;
            // pt_p_IdDispositivo.SetMandatory( TRUE )
            this.ptupuIdDispositivo.mandatory = true;
        }
        this.executePendingDependencyRules();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Dependency rule 2 for pt_p_TipoFirma argument
     */
    private executeDependecyRules2ptupuTipoFirma(): void {
        this.cacheDependencyRules.counter++;
        try {
            this.executeDependecyRules2ptupuTipoFirmaActions();
        } catch (e) {
            console.log(e);
            this.executePendingDependencyRules();
            this.changeDetectorRef.markForCheck();
        }
        this.cacheDependencyRules.counter--;
    }

    /**
     * Actions of dependency rule 2 for pt_p_TipoFirma argument
     */
    private executeDependecyRules2ptupuTipoFirmaActions(): void {
        // v <> NULL AND v <> "B"
        if (!this.util.isNull(this.ptupuTipoFirma.value) && !this.util.valueEquals(this.ptupuTipoFirma.value, 'B')) {
            // pt_p_IdDispositivo.SetVisible( FALSE )
            this.ptupuIdDispositivo.visible = false;
            // pt_p_IdDispositivo.SetMandatory( FALSE )
            this.ptupuIdDispositivo.mandatory = false;
            // pt_p_IdDispositivo.SetValue( NULL )
            this.ptupuIdDispositivo.setValue(null);
        }
        this.executePendingDependencyRules();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Dependency rule 3 for pt_p_TipoFirma argument
     */
    private executeDependecyRules3ptupuTipoFirma(): void {
        this.cacheDependencyRules.counter++;
        try {
            this.executeDependecyRules3ptupuTipoFirmaActions();
        } catch (e) {
            console.log(e);
            this.executePendingDependencyRules();
            this.changeDetectorRef.markForCheck();
        }
        this.cacheDependencyRules.counter--;
    }

    /**
     * Actions of dependency rule 3 for pt_p_TipoFirma argument
     */
    private executeDependecyRules3ptupuTipoFirmaActions(): void {
        // v = NULL
        if (this.util.isNull(this.ptupuTipoFirma.value)) {
            // pt_p_IdDispositivo.SetVisible( FALSE )
            this.ptupuIdDispositivo.visible = false;
            // pt_p_IdDispositivo.SetMandatory( FALSE )
            this.ptupuIdDispositivo.mandatory = false;
            // pt_p_IdDispositivo.SetValue( NULL )
            this.ptupuIdDispositivo.setValue(null);
        }
        this.executePendingDependencyRules();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Process the change in the pt_p_TipoFirma argument
     */
    public onptuputipofirmaChange(): void {
        this.clearValidationMessages();
        this.executeDependecyRulesptuputipofirma('SetValue', false);
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Evaluate dependendy rules for pt_p_TambienDeCRA argument
     * @param event Rule event
     * @param internal Internal or External
     */
    private executeDependecyRulesptuputambiendecra(event: string, internal: boolean): void {
        if (!this.cacheDependencyRules.checkCounter()) {
            return;
        }
        // Rule 1
        if ('SetValue' === event) {
            this.executeDependecyRules1ptupuTambienDeCRA();
        }
        // Rule 2
        if ('SetValue' === event) {
            this.executeDependecyRules2ptupuTambienDeCRA();
        }
        this.executePendingDependencyRules();
    }

    /**
     * Dependency rule 1 for pt_p_TambienDeCRA argument
     */
    private executeDependecyRules1ptupuTambienDeCRA(): void {
        this.cacheDependencyRules.counter++;
        try {
            this.executeDependecyRules1ptupuTambienDeCRAActions();
        } catch (e) {
            console.log(e);
            this.executePendingDependencyRules();
            this.changeDetectorRef.markForCheck();
        }
        this.cacheDependencyRules.counter--;
    }

    /**
     * Actions of dependency rule 1 for pt_p_TambienDeCRA argument
     */
    private executeDependecyRules1ptupuTambienDeCRAActions(): void {
        // v <> NULL AND v = true
        if (!this.util.isNull(this.util.getBool(this.ptupuTambienDeCRA.value)) &&
            this.util.valueEquals(this.util.getBool(this.ptupuTambienDeCRA.value), true)) {
            // pArea.SetVisible( TRUE )
            this.pArea.visible = true;
            // pClave.SetVisible( TRUE )
            this.pClave.visible = true;
            // pClaveCoaccion.SetVisible( TRUE )
            this.pClaveCoaccion.visible = true;
        }
        this.executePendingDependencyRules();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Dependency rule 2 for pt_p_TambienDeCRA argument
     */
    private executeDependecyRules2ptupuTambienDeCRA(): void {
        this.cacheDependencyRules.counter++;
        try {
            this.executeDependecyRules2ptupuTambienDeCRAActions();
        } catch (e) {
            console.log(e);
            this.executePendingDependencyRules();
            this.changeDetectorRef.markForCheck();
        }
        this.cacheDependencyRules.counter--;
    }

    /**
     * Actions of dependency rule 2 for pt_p_TambienDeCRA argument
     */
    private executeDependecyRules2ptupuTambienDeCRAActions(): void {
        // (v <> NULL AND v = false) OR v = NULL
        if ((!this.util.isNull(this.util.getBool(this.ptupuTambienDeCRA.value)) &&
            this.util.valueEquals(this.util.getBool(this.ptupuTambienDeCRA.value), false)) ||
            this.util.isNull(this.util.getBool(this.ptupuTambienDeCRA.value))) {
            // pArea.SetVisible( FALSE )
            this.pArea.visible = false;
            // pClave.SetVisible( FALSE )
            this.pClave.visible = false;
            // pClaveCoaccion.SetVisible( FALSE )
            this.pClaveCoaccion.visible = false;
            // pArea.SetValue( NULL )
            this.pArea.setValue(null);
            this.executeDependecyRulesparea('SetValue', true);
            // pClave.SetValue( NULL )
            this.pClave.setValue(null);
            // pClaveCoaccion.SetValue( NULL )
            this.pClaveCoaccion.setValue(null);
        }
        this.executePendingDependencyRules();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Process the change in the pt_p_TambienDeCRA argument
     */
    public onptuputambiendecraChange(): void {
        this.clearValidationMessages();
        this.executeDependecyRulesptuputambiendecra('SetValue', false);
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Evaluate dependendy rules for pArea argument
     * @param event Rule event
     * @param internal Internal or External
     */
    private executeDependecyRulesparea(event: string, internal: boolean): void {
        // Suplementary information
        if ('SetValue' === event) {
            this.setSuppInfoparea();
        }
        this.executePendingDependencyRules();
    }

    /**
     * Set the supplementary information to the pArea argument
     */
    private setSuppInfoparea(): void {

        const displaySetIC = 'id_Area';
        let numberMissingSupInfo = 0;
        for (const value of this.pArea.getSelectedInstances()) {
            if (!value.supplementaryInfo || value.supplementaryInfo === '') {
                numberMissingSupInfo++;
                this.cacheDependencyRules.addQueryInstance(ModelConstants.Area.name, value.oid, displaySetIC);
            }
        }

        if (numberMissingSupInfo === 0) {
            this.setOidFieldsValuepArea();
            this.executePendingDependencyRules();
            this.changeDetectorRef.markForCheck();
        } else {
            this.notifyCallToTheBackEnd();
            this.cacheDependencyRules.searchAll().then(() => {
                this.notifyAnswerFromToTheBackEnd();
                for (const value of this.pArea.getSelectedInstances()) {
                    if (!value.supplementaryInfo || value.supplementaryInfo === '') {
                        value.supplementaryInfo = this.cacheDependencyRules.getText(ModelConstants.Area.name,
                            value.oid, displaySetIC, [Field.dtAuto]);
                    }
                }
                this.setOidFieldsValuepArea();
                this.executePendingDependencyRules();
                this.changeDetectorRef.markForCheck();
            });
        }
    }

   /**
    * Set values to the Oid fields and supplementary information of pArea lense argument
    */
    private setOidFieldsValuepArea(): void {
        if (this.pArea.getSelectedInstances().length > 0) {
            if (this.pArea.getSelectedInstances().length > 1) {
                this.pArea.oidFields = [null];
                this.pArea.editorsEnabled = false;
                this.pArea.supplementaryInfo = this.langMng.translateTextWithParams(
                    LanguageMng.fsNumberInstacesSelected, '',
                    this.pArea.getSelectedInstances().length.toString());
            } else {
                this.pArea.editorsEnabled = true;
                this.pArea.oidFields[0] = this.pArea.getSelectedInstances()[0].oid.id_area;
                this.pArea.supplementaryInfo = this.pArea.getSelectedInstances()[0].supplementaryInfo;
            }
        } else {
            this.pArea.oidFields = Array.apply(null, Array(1));
            this.pArea.supplementaryInfo = '';
        }

    }

    /**
     * Process the change in the pArea lense argument
     */
    public onpareaChange(): void {
        this.clearValidationMessages();
        let fieldsHasValue = true;
        for (const field of this.pArea.oidFields) {
            if (!field) {
                fieldsHasValue = false;
                break;
            }
        }

        this.pArea.cleanSelectedInstances();
        if (fieldsHasValue) {
            const oid = {
            id_area: this.pArea.oidFields[0]
            };
            this.pArea.setValue(oid);
            this.cacheDependencyRules.addQueryInstance(ModelConstants.Area.name, oid,
                'id_Area');
            this.notifyCallToTheBackEnd();
            this.cacheDependencyRules.searchAll().then(() => {
                this.notifyAnswerFromToTheBackEnd();
                this.pArea.getSelectedInstances().forEach((value) => {
                    if (!this.cacheDependencyRules.existInstance(ModelConstants.Area.name, value.oid)) {
                        this.pArea.setValidationMessage(this.langMng.translateTextWithParams(
                            LanguageMng.fsErrorNotExist, '', this.pArea.alias));
                        this.pArea.cleanSelectedInstances();
                        this.pArea.supplementaryInfo = '';
                    } else {
                        this.executeDependecyRulesparea('SetValue', false);
                    }
                    this.changeDetectorRef.markForCheck();
                });
            });
        }
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Clean pArea argument value
     */
    public cleanparea(): void {
        this.clearValidationMessages();
        this.pArea.clean();
        this.pArea.oidFields = Array.apply(null, Array(1));
        this.executeDependecyRulesparea('SetValue', false);
        this.changeDetectorRef.markForCheck();
    }

   /**
    * Process the click in the lense of any object-valued argument
    * @param argumentName Name of the argument
    */
    public doSearchAction(argumentName: string): void {
        const exchInfo = this.getSelectionForwardExchInfo();
        exchInfo.originArgumentName = argumentName;
        if ('puthisContacto'.toLowerCase() === argumentName.toLowerCase()) {
            this.puthisContacto.completeSelectionForwardExchInfo(exchInfo);
            exchInfo.targetIUName = 'PIU_Sistema';
            this.util.navigateSelectionForward(exchInfo);
        }
        if ('pArea'.toLowerCase() === argumentName.toLowerCase()) {
            this.pArea.completeSelectionForwardExchInfo(exchInfo);
            exchInfo.targetIUName = 'PIU_Area4Sistema';
            exchInfo.navigationalFilterIdentity = [];
            exchInfo.navigationalFilterIdentity.push('Contacto_TEDIT_pArea_NavFilter0');
            exchInfo.navigationalFilterVariables = this.getNavigationFilterVariables();
            this.util.navigateSelectionForward(exchInfo);
        }
    }

    /**
     * Returns true if all elements contained in the group are visible
     * @param groupId Group id.
     */
    public isGroupVisible(groupId: string): boolean {
        let isVisible = true;

        if (groupId === '7') {
            isVisible = this.pArea.visible ||
                this.pClave.visible ||
                this.pClaveCoaccion.visible;
        }
        if (groupId === '8') {
            isVisible = this.ptupuRecibePresupuestos.visible ||
                this.ptupuRecibePartes.visible ||
                this.ptupuRecibeFacturas.visible ||
                this.ptupuPuedeFirmar.visible;
        }
        if (groupId === '9') {
            isVisible = this.ptupuDNI.visible ||
                this.ptupuMovil.visible ||
                this.ptupuTipoFirma.visible ||
                this.ptupuIdDispositivo.visible;
        }
        return isVisible;
    }

    /**
     * Returns the alias group
     * @param groupId Group id
     */
    public getGroupAlias(groupId: string): string {
        let alias = '';

        if (groupId === '7') {
            alias = this.langMng.translateText('7',
                'Datos para CRA');
        }
        if (groupId === '8') {
            alias = this.langMng.translateText('8',
                'Permisos');
        }
        if (groupId === '9') {
            alias = this.langMng.translateText('9',
                'Datos para firma');
        }
        return alias;
    }
}
